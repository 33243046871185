function NotFound() {
    return ( 
        <>
        <div>
            <p>404</p>
        </div>
        </>
     );
}

export default NotFound;