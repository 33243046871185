import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import imgLogo from '../assets/lgci.png';
import './header.css';

function Header() {
  return (
    <Navbar sticky="top" collapseOnSelect expand="xl" bg="primary" variant="dark">
      <Container>
        <Navbar.Brand className='sitename' href="/">
          <img className='imgLogo' src={imgLogo} alt="Logo" /> LGCI
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='ms-auto navItems'>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/course">Course</Nav.Link>
            <Nav.Link href="/studentPanel">Student Panel</Nav.Link>
            <Nav.Link href="/gallery">Gallery</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/"><button type="button" className="btn btn-light">Enquiry</button></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;