import React, { useState, useEffect } from 'react';
import './home.css';
import axios from 'axios';
import { Modal, Button, Table, Navbar, Spinner } from 'react-bootstrap';

function Home() {
  const [searchTerm, setSearchTerm] = useState('');
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      alert(`Searching for "${searchTerm}"...`);
    } else {
      alert('Please enter a search term.');
    }
  };

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCourse(null);
  };

  useEffect(() => {
    const apiUrl = 'https://api.lifeguidance.in/mobileUser/api/getCourse.php';

    axios.get(apiUrl)
      .then((response) => {
        setCourses(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Failed to fetch courses');
        setLoading(false);
      });
  }, []);

  return (
    <div className="bgg text-center pt-5">
      <section className="container d-flex flex-column align-items-center pt-5" style={{ height: '70vh' }}>
        <h1 className="">Discover Your Potential</h1>
        <p className="mb-4">Join <span className='lgci-text'>Life Guidance Computer Institute</span> and unlock your future with expert training.</p>

        <div className="search-bar mb-3">
          <input
            type="text"
            className="search-input"
            placeholder="What do you want to learn today?"
            value={searchTerm}
            onChange={handleInputChange}
            aria-label="Search Courses"
          />
          <button className="search-button" onClick={handleSearch}>Search</button>
        </div>


        <div className="d-flex justify-content-center mt-1">
          <button className="btn btn-outline-light mx-2">Explore Courses</button>
          <button className="btn btn-outline-warning mx-2">Contact Us</button>
        </div>
      </section>

      <section className='bg-2 py-5 d-flex justify-content-center'>
        <div className="row container justify-content-center">
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : error ? (
            <p className="text-danger">{error}</p>
          ) : (
            courses.map((course) => (
              <div className="col-md-2 mb-4" key={course.id} onClick={() => handleCourseClick(course)}>
                <div className="card course-card">
                  <div className="card-body">
                    <h5 className="card-text text-course">{course.course_name}</h5>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </section>

      {selectedCourse && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedCourse.course_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='selected-course-p'>Details for {selectedCourse.course_name}</p>
            <p className='selected-course-p'>{selectedCourse.course_desc}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default Home;