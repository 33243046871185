import React from "react";
import instructor1 from "../assets/lgci.png";
import kapil from "../assets/kapil.jpg";
import pagal from "../assets/pagal.jpg";
import './about.css';

const instructors = [
  {
    name: "Shailendra Kumar (Kapil Sir)",
    image: kapil,
    description: "Trainer and Life Coach",
  },
  {
    name: "Mr. Aabid Husain",
    image: pagal,
    description: "Software Developer",
  },
];

const instituteDetails = {
  name: "Life Guidance Computer Institute",
  description:
    "Life Guidance Computer Institute, founded in late 2022, is dedicated to offering top-notch computer training and life coaching under the expert guidance of Mr. Shailendra Kumar (Kapil Sir). We aim to empower students with the necessary skills to excel in the IT industry and beyond.",
  address: "Prayagraj Road , Maharajganj Badlapur Jaunpur",
  contact: "Mobile No: +91 9670927047 | Email: lgci@lifeguidance.in",
};

const About = () => {
  return (
    <div className="p-2 about-main">
      <div className="pageContainer container">
        <h1 className="heading">Meet Our Instructors</h1>
        <div className="instructorContainer">
          {instructors.map((instructor, index) => (
            <div key={index} className="instructorCard">
              <img
                src={instructor.image}
                alt={instructor.name}
                className="instructorImage"
              />
              <h3 className="instructorName">{instructor.name}</h3>
              <p className="instructorDescription">{instructor.description}</p>
            </div>
          ))}
        </div>
        <div className="instituteContainer">
          <h2>About Our Institute</h2>
          <p className="text-color-subtitle">{instituteDetails.description}</p>
          <p className="text-color-subtitle">
            <strong>Address:</strong> {instituteDetails.address}
          </p>
          <p className="text-color-subtitle">
            <strong>Contact:</strong> {instituteDetails.contact}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;