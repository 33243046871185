import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './utility/header';
import Footer from './utility/footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="body-content outlet">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;