import React, { useState } from "react";
import './verify_lg_id.css';

const VerifyLGid = () => {
  const [lgId, setLgId] = useState("");
  const [dob, setDob] = useState("");
  const [verificationResult, setVerificationResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showVerifyAnotherButton, setShowVerifyAnotherButton] = useState(false); // New state

  const handleInputChange = (e) => {
    if (e.target.name === "lgId") {
      setLgId(e.target.value);
    } else if (e.target.name === "dob") {
      setDob(e.target.value);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setVerificationResult(null);
    setShowVerifyAnotherButton(false); // Hide the button initially

    try {
      const response = await fetch(
        `https://api.lifeguidance.in/mobileUser/api/checkLgId.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            stu_lg_id: lgId,
            stu_dob: dob,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Network response was not ok");
      }

      const data = await response.json();
      setVerificationResult(data);
      setShowVerifyAnotherButton(true); // Show button after verification
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyAnother = () => {
    // Reset fields for another verification
    setLgId("");
    setDob("");
    setVerificationResult(null);
    setShowVerifyAnotherButton(false); // Hide button after clicking
  };

  return (
    <div className="verify-container">
      <h1>Verify LG ID</h1>
      <form onSubmit={handleVerification} className="form">
        <label htmlFor="lgId" className="form-label">Enter LG ID:</label>
        <input
          type="text"
          id="lgId"
          name="lgId"
          value={lgId}
          onChange={handleInputChange}
          placeholder="Enter your LG ID"
          className="form-input"
          required
        />
        
        <label htmlFor="dob" className="form-label">Enter Date of Birth:</label>
        <input
          type="date"
          id="dob"
          name="dob"
          value={dob}
          onChange={handleInputChange}
          className="form-input"
          required
        />
        
        <button type="submit" disabled={loading} className="form-button">
          {loading ? "Verifying..." : "Verify"}
        </button>
      </form>

      {error && <p className="error-message">{error}</p>}

      {verificationResult && (
        <div className="verification-result">
          {verificationResult.exists ? (
            <div>
              <p>LG ID is valid!</p>
              <p>Student Name: {verificationResult.name}</p>
              <p>Date of Birth: {verificationResult.stu_dob}</p>
            </div>
          ) : (
            <p>LG ID or Date of Birth is not valid.</p>
          )}
        </div>
      )}

      {showVerifyAnotherButton && ( // Conditionally render the button
        <button onClick={handleVerifyAnother} className="form-button">
          Verify Another ID
        </button>
      )}
    </div>
  );
};

export default VerifyLGid;