import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faIdCard, faDownload, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import './studentPanel.css';
import { Button, Table, Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { Link, Outlet, useLocation } from 'react-router-dom';

function StudentPanel() {
    const location = useLocation();

    return (
        <>
            <div className='stu-panel-container'>
                <Container fluid className='stu-panel-container container'>
                    <Row className='py-3'>
                        <Col>
                            <h2 className='text-center'>Student Panel</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className='sidebar-stu-panel'>
                            <nav>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link to="verifyLGid" className="nav-link">Verify LG ID</Link>
                                    </li>
                                </ul>
                            </nav>
                        </Col>
                        <Col md={9} className='outlet-stu-panel'>
                            {/* Conditional rendering of the outlet or a message if no route is selected */}
                            {location.pathname === "/studentpanel" ? (
                                <div className="text-center">
                                    <p>Please select an option from the sidebar.</p>
                                </div>
                            ) : (
                                <Outlet />
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default StudentPanel;