import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './course.css';
import { Modal, Button, Table, Navbar } from 'react-bootstrap';

const CoursePage = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null); // State to hold selected course

    useEffect(() => {
        const apiUrl = 'https://api.lifeguidance.in/mobileUser/api/getCourse.php';

        axios.get(apiUrl)
            .then((response) => {
                setCourses(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setError('Failed to fetch courses');
                setLoading(false);
            });
    }, []);

    const handleShowModal = (course) => {
        setSelectedCourse(course); // Set the selected course to show in modal
        setShowModal(true); // Open the modal
    };

    const handleCloseModal = () => setShowModal(false); // Close the modal

    if (loading) {
        return (
            <div className="loading-container">
                <div className="spinner"></div>
            </div>
        );
    }

    if (error) {
        return <div className="alert alert-danger">{error}</div>;
    }

    return (
        <div className="container color-white">
            <h1 className="text-center my-4" style={{color: 'black'}}>Courses ({courses.length})</h1>
            <div className="row">
                {courses.map((course) => (
                    <div className="col-md-3 mb-4" key={course.id}>
                        <div className="card h-100">
                            <img src={course.course_img} className="card-img-top" alt={course.title} />
                            <div className="card-body">
                                <h5 className="card-title">{course.course_name}</h5>
                                <p className="card-text course_desc">{course.course_desc}</p>
                                <p className='learn-more-btn' onClick={() => handleShowModal(course)}>Learn More</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {selectedCourse && (
                <Modal show={showModal} onHide={handleCloseModal} dialogClassName="full-width-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedCourse.course_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={selectedCourse.course_img} className="img-fluid mb-3" alt={selectedCourse.course_name} />
                        <p className='model-color'>{selectedCourse.course_desc}</p>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td>Duration</td>
                                    <td>{selectedCourse.course_duration}</td>
                                </tr>
                                <tr>
                                    <td>Fee</td>
                                    <td>{selectedCourse.course_price}. Rs</td>
                                </tr>
                                <tr>
                                    <td>Course Category</td>
                                    <td>{selectedCourse.course_cat}</td>
                                </tr>
                                <tr>
                                    <td>Course Level</td>
                                    <td>{selectedCourse.course_level}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default CoursePage;