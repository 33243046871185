import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';

import Home from './utility/home';
import About from './navMenu/about';
import NotFound from './utility/notfound';
import Course from './navMenu/course';
import StudentPanel from './navMenu/studentPanel';
import Gallery from './navMenu/gallery';
import Contact from './navMenu/contact';
import VerifyLGid from './studentPanel/verify_lg_id';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="course" element={<Course />}/>
          <Route path="gallery" element={<Gallery />} />
          <Route path="studentPanel" element={<StudentPanel />}>
            <Route path="verifyLGid" element={<VerifyLGid />} />
            <Route path="settings" element={<Contact />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
