// src/components/Gallery.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import './gallery.css';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get("https://api.lifeguidance.in/auth/students.php");
        setImages(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="container centerText">
      <h2 className="text-center my-4">Image Gallery ({images.length})</h2>
      <div className="row justify-content-center">
        {images.slice().reverse().map((image) => (
          <div className="userCard">
          <img
            src={image.stu_img}
            alt={image.stu_name}
            className="userImage"
          />
        </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;